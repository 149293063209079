<template lang="pug">
div
  div(v-if="showModal")
    vehicle-modal(:vehicle="vehicle", @close="showModal = false")
  card#cards
    div.scroller
      .row
        .d-flex.flex-column.col-md-12.col-xl-6.mb-4
          span.matador-h3 Lokasi Kendaraan
          span.matador-caption.matador-text-03.mt-2 {{ lastUpdateString }}
          .d-flex.mt-3
            a.d-flex.status-frame.off.flex-fill.mr-3(href="javascript:;" :class="{'inactive': !filter.includes('off')}" @click="toggleFilter('off')")
              img.status-icon(src="@/assets/img/truck-off.png" v-if="filter.includes('off')")
              img.status-icon(src="@/assets/img/truck-inactive.png" v-else)
              .d-flex.flex-column.ml-2.my-1
                span.status-label.matador-text-03 OFF
                span.matador-h3.matador-text-02 {{ device.offline }}
            a.d-flex.status-frame.idle.flex-fill.mx-3(href="javascript:;" :class="{'inactive': !filter.includes('idle')}" @click="toggleFilter('idle')")
              img.status-icon(src="@/assets/img/truck-idle.png" v-if="filter.includes('idle')")
              img.status-icon(src="@/assets/img/truck-inactive.png" v-else)
              .d-flex.flex-column.ml-2.my-1
                span.status-label.matador-text-03 IDLE
                span.matador-h3.matador-text-02 {{ device.idle }}
            a.d-flex.status-frame.on.flex-fill.ml-3(href="javascript:;" :class="{'inactive': !filter.includes('on')}" @click="toggleFilter('on')")
              img.status-icon(src="@/assets/img/truck-on.png" v-if="filter.includes('on')")
              img.status-icon(src="@/assets/img/truck-inactive.png" v-else)
              .d-flex.flex-column.ml-2.my-1
                span.status-label.matador-text-03 ON
                span.matador-h3.matador-text-02 {{ device.online }}
          //- matador-search#search-box.mt-4(
          //-   :isFlexGrow="false"
          //-   placeholder="Search Vehicle, VIN or location",
          //-   autocomplete="off"
          //- )
          .d-flex.mt-3
            matador-search.mt-1(
              style="width:85%;height" 
              :isFlexGrow="false"
              placeholder="Cari Nama Kendaraan, Nomor Polisi atau Lokasi Sekarang",
              autocomplete="off"
              v-model="searchButton"
            )
          
            .accordion.mt-1.ml-1(role='tablist')
              b-button.matador-bg-03( @click="showTableFunc" style="height: 40px;border-radius: 5px;") 
                .d-flex
                  div.w-100(style="text-align: left !important;")
                    .matador-h5.matador-text-03(v-show="showTable!=true" ) Show &nbsp;
                    .matador-h5.matador-text-03(v-show="showTable!=false") Hide &nbsp;
                  span.matador-text-03(v-show="showTable!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
                  span.matador-text-03(v-show="showTable!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline        
          b-collapse#accordion-1.mt-4.flex-grow-1(:visible='showTable ' accordion='my-accordion' role='tabpanel' appear)

            bs-table(
                  ref="table",
                  :columns="table.columns",
                  :options="tableOpions",
                  :data="filteredVehicles",
                  @on-post-body="vueFormatterPostBody"
                )
              //- @on-dbl-click-row="onClickRow"
        .d-flex.flex-column.col-md-12.col-xl-6(v-show="showTable")
          .d-flex.flex-fill.flex-column
            .d-flex.flex-fill.flex-column
              #map(style="height: 100vh" v-show="showTable")
      .row.my-10.mb-3
        .d-flex.flex-column.col-md-12.col-xl-12
          .d-flex.flex-fill.flex-column
            .d-flex.flex-fill.flex-column
              //- loader(:loading="loading" :size="42")
              #map2(style="height: 75vh" v-show="!showTable")
</template>
<script>
import BarChart from "@/components/Chartjs/BarChart";
import LineChart from "@/components/Chartjs/LineChart";
import DropdownDashboard from "@/components/DropdownDashboard.vue";
import { Card, ChartCard, PaperTable, StatsCard } from "@/components/index";
import VehicleModal from "@/components/Matador/VehicleModal";
import tableMixin from "@/mixins/table";
import {
  getVehicleDetail,
  getVehicleListExpress,
  getVehicleSpeed,
} from "@/utils/api";
import { LATITUDE_ASEAN, LONGITUDE_ASEAN, ZOOM_ASEAN } from "@/utils/map";
import { minifyChartAmount } from "@/utils/utilities";
import dayjs from "dayjs";
import { nothing } from "immer";
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";
import Datepicker from "vuejs-datepicker";
import {
  getFuels,
  getIdleTime,
  getOperatingHour,
  getOperatingMile,
  getTemperature,
} from "../utils/api";

export default {
  mixins: [tableMixin],

  components: {
    StatsCard,
    ChartCard,
    Card,
    PaperTable,
    BarChart,
    LineChart,
    ChevronLeftIcon,
    ChevronRightIcon,
    Datepicker,
    VehicleModal,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      loading: false,
      showTable: false,
      searchButton: "",
      perPage: 10,
      totalRows: 0,
      speedCalendar: false,
      speedType: "hourly",
      fuelType: "hourly",
      theFuelType: "hourly",
      idleType: "hourly",
      tempType: "hourly",
      mileType: "hourly",
      filter: ["on", "idle", "off"],
      device: {},
      device2: {},
      markers: [],
      markers2: [],
      vehicles: [],
      vehicle: {},
      vehicles2: [],
      lastUpdate: null,
      bigMap: true,
      map: null,
      map2: null,
      infoWindow: null,
      table: {
        columns: [
          {
            title: "Nama Kendaraan",
            field: "vehicle_name",
            align: "center",
            searchable: true,
            sortable: true,
            widthUnit: "%",
            width: "25",
            formatter: (value, row) => {
              var self = this;
              return this.vueFormatter({
                template: `<div>
                  <button class="dashboard-table-button" @click="onClickRow(row)" >{{value}}</button>
                </div>`,
                data: { row, value },
                methods: {
                  onClickRow: this.onClickRow,
                },
                // components : {
                //   DropdownDashboard
                // }
              });
            },
          },
          {
            field: "vin",
            title: "Nomor Polisi",
            align: "center",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "25",
            formatter: (value, row) => {
              var self = this;
              return this.vueFormatter({
                template: `<div>
                  <button class="dashboard-table-button" @click="onClickRow(row)" >{{value}}</button>
                </div>`,
                data: { row, value },
                methods: {
                  onClickRow: this.onClickRow,
                },
                // components : {
                //   DropdownDashboard
                // }
              });
            },
          },
          {
            field: "latlong",
            title: "Lokasi Sekarang",
            align: "center",
            sortable: true,
            searchable: false,
            widthUnit: "%",
            width: "25",
            formatter: (value, row) => {
              var self = this;
              return this.vueFormatter({
                template: `<div>
                  <button class="dashboard-table-button" @click="onClickRow(row)" >{{value}}</button>
                </div>`,
                data: { row, value },
                methods: {
                  onClickRow: this.onClickRow,
                },
                // components : {
                //   DropdownDashboard
                // }
              });
            },
          },
          {
            field: "unit_id",
            title: "",
            align: "left",
            formatter: (value, row) => {
              var self = this;
              return this.vueFormatter({
                template: `<div >
  <DropdownDashboard tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
    <li class="p-3 matador-text-03" v-on:click="seeVehicleDetail(row)">View Vehicle</li>
  </DropdownDashboard>
</div>`,
                data: { row },
                methods: {
                  seeVehicleDetail(row) {
                    self.fetchVehicleDetail(row.unit_id);
                    self.showModal = true;
                  },
                },
                components: {
                  DropdownDashboard,
                },
              });
            },
            searchable: false,
            widthUnit: "%",
            width: "5",
          },
        ],
      },
      showModal: false,
      speeds: [],
      theFuels: [],
      fuels: [],
      miles: [],
      idles: [],
      temps: [],
      currentDateFuel: new Date(),
      currentDateSpeed: new Date(),
      currentDateMile: new Date(),
      currentDateIdle: new Date(),
      currentDateTemp: new Date(),
      currentDateTheFuel: new Date(),
    };
  },
  watch: {
    filteredVehicles(newVal) {
      var that = this;
      var checker = setInterval(function () {
        if (window.google) {
          if (!that.map) that.initMap(newVal);
          if (!that.map2) that.initMap(newVal);
          console.log(window.google, "filter interval 8 detik");
          // if(bigMap==false){
          //   if(!that.map) that.initMap(newVal);
          //   }else{
          //   if(!that.map2) that.initMap(newVal);
          //   } //here
          that.initMarker(newVal);
          that.initMarker2(newVal);

          clearInterval(checker);
        }
      }, 2000);
    },
    currentDateSpeed() {
      this.fetchVehicleSpeed();
    },
    currentDateFuel() {
      this.fetchVehicleFuel();
    },
    currentDateMile() {
      this.fetchVehicleMile();
    },
    currentDateIdle() {
      this.fetchVehicleIdle();
    },
    currentDateTemp() {
      this.fetchVehicleTemp();
    },
    currentDateTheFuel() {
      this.fetchVehicleTheFuel();
    },
    speedType() {
      this.fetchVehicleSpeed();
    },
    fuelType() {
      this.fetchVehicleFuel();
    },
    mileType() {
      this.fetchVehicleMile();
    },
    idleType() {
      this.fetchVehicleIdle();
    },
    tempType() {
      this.fetchVehicleTemp();
    },
    theFuelType() {
      this.fetchVehicleTheFuel();
    },
  },
  computed: {
    lastUpdateString() {
      if (("last update", this.lastUpdate)) {
        console.log(this.lastUpdate);
        return `Update Terakhir : ${dayjs(this.lastUpdate).format(
          "DD MMMM YYYY HH:mm",
        )} (UTC +07:00)`;
      }
      return "";
    },
    filteredVehicles() {
      return this.vehicles.filter((item) => {
        // console.log(item,'ini item')
        // console.log(item.status,"ini item status")
        // console.log(this.filter.includes(item.status),"heres")
        // return this.filter.includes(item.status);
        return (
          this.filter.includes(item.status) &&
          ((item.vehicle_name || "")
            .toLowerCase()
            .indexOf(this.searchButton.toLowerCase()) >= 0 ||
            (item.vin || "")
              .toLowerCase()
              .indexOf(this.searchButton.toLowerCase()) >= 0)
        );
      });
    },
    tableOpions() {
      return {
        ajax: this.fetchVehicleList,
        search: true,
        searchSelector: "#search-box",
        pagination: true,
        showSearchButton: true,
        sortable: true,
        paginationHAlign: "center",
        paginationDetailHAlign: "right",
        paginationParts: ["pageList"],
        classes: ["table", "table-hover"],
      };
    },
    getCurrentDateSpeed() {
      return dayjs(this.currentDateSpeed).format("DD MMMM YYYY");
    },
    getCurrentDateFuel() {
      return dayjs(this.currentDateFuel).format("DD MMMM YYYY");
    },
    getCurrentDateIdle() {
      return dayjs(this.currentDateIdle).format("DD MMMM YYYY");
    },
    getCurrentDateMile() {
      return dayjs(this.currentDateMile).format("DD MMMM YYYY");
    },
    getCurrentDateTemp() {
      return dayjs(this.currentDateTemp).format("DD MMMM YYYY");
    },
    getCurrentDateTheFuel() {
      return dayjs(this.currentDateTheFuel).format("DD MMMM YYYY");
    },
    trip() {
      return Array.from(Array(3).keys()).map(() => {
        return {
          origin: "Alaung Phaya St, Shwebo, Myanmar (Burma)",
          departure_time: "02:00",
          distance: 26.34,
          duration: 60,
          destination: "Unnamed Road, Myanmar (Burma)",
          arrival_time: "03:00",
        };
      });
    },
    speedChart() {
      if (!this.speeds && this.speeds.length === 0)
        return { data: { labels: [], datasets: [] }, options: {} };
      return {
        data: {
          labels: this.speeds.map((item) =>
            Number.isInteger(item.label)
              ? String(item.label).length == 1
                ? "0" + String(item.label)
                : item.label
              : dayjs(item.label).format("DD MMM YY"),
          ),
          datasets: [
            {
              label: "Average Speed",
              backgroundColor: "#7998B9",
              data: this.speeds.map((item) => item.average),
              stack: "speed-stack",
              beginAtZero: true,
            },
            {
              label: "Top Speed",
              backgroundColor: "#FDCA40",
              data: this.speeds.map((item) => item.top_speed),
              stack: "speed-stack",
              beginAtZero: true,
            },
          ],
        },
        options: {
          tooltips: { enabled: false },
          hover: { mode: null },
          legend: {
            display: false,
            onClick: (e) => e.stopPropagation(),
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawOnChartArea: false,
                },
              },
            ],
          },
        },
      };
    },
    idleChart() {
      if (!this.idles && this.idles.length === 0)
        return { data: { labels: [], datasets: [] }, options: {} };
      return {
        data: {
          labels: this.idles.map((item) =>
            Number.isInteger(item.label)
              ? String(item.label).length == 1
                ? "0" + String(item.label)
                : item.label
              : dayjs(item.label).format("DD MMM YY"),
          ),
          datasets: [
            {
              label: "Idle Times",
              backgroundColor: "#FDCA40",
              data: this.idles.map((item) => item.total / 60),
              beginAtZero: true,
              borderColor: "#FDCA40",
              borderWidth: 5,
            },
          ],
        },
        options: {
          tooltips: { enabled: false },
          hover: { mode: null },
          legend: {
            display: false,
            onClick: (e) => e.stopPropagation(),
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawOnChartArea: false,
                },
              },
            ],
          },
        },
      };
    },
    fuelChart() {
      if (!this.fuels || this.fuels.length === 0)
        return { data: { labels: [], datasets: [] }, options: {} };
      return {
        data: {
          labels: this.fuels.map((item) =>
            Number.isInteger(item.label)
              ? String(item.label).length == 1
                ? "0" + String(item.label)
                : item.label
              : dayjs(item.label).format("DD MMM YY"),
          ),
          datasets: [
            {
              label: "Operating Hours",
              backgroundColor: "#DDE5ED",
              data: this.fuels.map((item) => item.total / 3600),
              beginAtZero: true,
              borderColor: "#7998B9",
              borderWidth: 5,
            },
          ],
        },
        options: {
          tooltips: { enabled: true },
          hover: { mode: true },
          interaction: {
            intersect: false,
          },
          radius: 0,
          elements: {
            line: {
              tension: 0,
            },
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                  callback: minifyChartAmount,
                },
              },
            ],
          },
        },
      };
    },
    tempChart() {
      if (!this.temps || this.temps.length === 0)
        return { data: { labels: [], datasets: [] }, options: {} };
      return {
        data: {
          labels: this.temps.map((item) =>
            Number.isInteger(item.label)
              ? String(item.label).length == 1
                ? "0" + String(item.label)
                : item.label
              : dayjs(item.label).format("DD MMM YY"),
          ),
          datasets: [
            {
              label: "Operating Hours",
              backgroundColor: "#FDCA40",
              data: this.temps.map((item) => item.total),
              beginAtZero: true,
              borderColor: "#FDCA40",
              borderWidth: 5,
            },
          ],
        },
        options: {
          tooltips: { enabled: false },
          hover: { mode: null },
          interaction: {
            intersect: false,
          },
          radius: 0,
          elements: {
            line: {
              tension: 0,
            },
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                  callback: minifyChartAmount,
                },
              },
            ],
          },
        },
      };
    },
    theFuelChart() {
      if (!this.theFuels || this.theFuels.length === 0) {
        return { data: { labels: [], datasets: [] }, options: {} };
      }
      return {
        data: {
          labels: this.theFuels.map((item) =>
            Number.isInteger(item.label)
              ? String(item.label).length == 1
                ? "0" + String(item.label)
                : item.label
              : dayjs(item.label).format("DD MMM YY"),
          ),
          datasets: [
            {
              label: "Fuel",
              backgroundColor: "#DDE5ED",
              data: this.theFuels.map((item) => item.total / 1000), // Konversi ke liter
              beginAtZero: true,
              borderColor: "#7998B9",
              borderWidth: 5,
            },
          ],
        },
        options: {
          tooltips: { enabled: false },
          hover: { mode: null },
          legend: {
            display: false,
            onClick: (e) => e.stopPropagation(),
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true, // Memulai dari nol
                  callback: function (value) {
                    return `${value % 1 === 0 ? value : value.toFixed(1)} L`; // Menampilkan angka bulat tanpa desimal dan angka desimal dengan satu angka
                  },
                },
              },
            ],
          },
        },
      };
    },

    mileChart() {
      if (!this.miles || this.miles.length === 0)
        return { data: { labels: [], datasets: [] }, options: {} };
      return {
        data: {
          labels: this.miles.map((item) =>
            Number.isInteger(item.label)
              ? String(item.label).length == 1
                ? "0" + String(item.label)
                : item.label
              : dayjs(item.label).format("DD MMM YY"),
          ),
          datasets: [
            {
              label: "Operating Miles",
              backgroundColor: "#DDE5ED",
              data: this.miles.map((item) => item.total / 1000),
              beginAtZero: true,
              borderColor: "#7998B9",
              borderWidth: 5,
            },
          ],
        },
        options: {
          tooltips: { enabled: false },
          hover: { mode: null },
          interaction: {
            intersect: false,
          },
          radius: 0,
          elements: {
            line: {
              tension: 0,
            },
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                  callback: minifyChartAmount,
                },
              },
            ],
          },
        },
      };
    },
  },
  methods: {
    fetchVehicleInterval() {
      setInterval(() => {
        if (this.$route.name == "dashboard") {
          getVehicleListExpress().then(
            ({ data: { code, result, message } }) => {
              if (code == 200) {
                this.device =
                  result["device"] && result["device"].length > 0
                    ? result["device"][0]
                    : { offline: "0", idle: "0", online: "0" };

                const updatedVehicles = result["vehicle"]
                  ? result["vehicle"].map((item) => ({
                      ...item,
                      vehicle_name: item.vehicle_name || item.unit_id,
                      latlong:
                        item.longitude && item.latitude
                          ? `${item.longitude}, ${item.latitude}`
                          : " - ",
                    }))
                  : [];

                this.vehicles = updatedVehicles;
                this.initMarker(updatedVehicles, "map");
                this.initMarker(updatedVehicles, "map2");
              }
            },
          );
        }
      }, 12000);
    },
    showTableFunc() {
      if (this.showTable) {
        this.showTable = false;
      } else {
        this.showTable = true;
      }
    },
    getHoverContent(item) {
      return `<table style="border: none">
      <tr><td><span class="map-info-title">Nomor Polisi</span></td><td><span class="matador-text-02">&nbsp:&nbsp&nbsp</span></td><td><span class="matador-text-02">${item.vin}</span></td></tr>
      <tr><td><span class="map-info-title">Nama Perangkat</span></td><td><span class="matador-text-02">&nbsp:&nbsp&nbsp</span></td><td><span class="matador-text-02">${item.vehicle_name}</span></td></tr>
      <tr><td><span class="map-info-title">Tipe Kendaraan</span></td><td><span class="matador-text-02">&nbsp:&nbsp&nbsp</span></td><td><span class="matador-text-02">${item.name}</span></td></tr>
      <tr><td><span class="map-info-title">Lokasi Sekarang</span></td><td><span class="matador-text-02">&nbsp:&nbsp&nbsp</span></td><td><span class="matador-text-02">${item.latlong}</span></td></tr>
       </table>`;
      return item.vehicle_name;
    },
    onClickRow(row, element, field) {
      var latLng = new window.google.maps.LatLng(row.latitude, row.longitude);
      // if(this.bigMap==false){
      //   this.map.setZoom(15);//here
      //   this.map.panTo(latLng);
      //   }else{
      //   this.map2.setZoom(15);
      //   this.map2.panTo(latLng);
      //   }
      this.map.setZoom(15); //here
      this.map.panTo(latLng);
      this.map2.setZoom(15);
      this.map2.panTo(latLng);
    },
    toggleFilter(filter) {
      if (this.filter.includes(filter)) {
        this.filter = this.filter.filter((v) => v !== filter);
      } else {
        this.filter.push(filter);
      }
    },
    setCurrentDateSpeed(value) {
      this.currentDateSpeed = value;
    },
    setCurrentDateFuel(value) {
      this.currentDateFuel = value;
    },
    setCurrentDateIdle(value) {
      this.currentDateIdle = value;
    },
    setCurrentDateMile(value) {
      this.currentDateMile = value;
    },
    setCurrentDateTemp(value) {
      this.currentDateTemp = value;
    },
    setCurrentDateTheFuel(value) {
      this.currentDateTheFuel = value;
    },
    showCalendar(ref) {
      this.$refs[ref].showCalendar();
    },
    closeDatePicker(event) {
      const className = event.target.className || "";
      if (
        className.indexOf("btn up") == -1 &&
        className.indexOf("next") == -1 &&
        className.indexOf("prev") == -1
      ) {
        this.$refs.speedDatePicker.close();
        this.$refs.fuelDatePicker.close();
        this.$refs.idleDatePicker.close();
        this.$refs.mileDatePicker.close();
        this.$refs.tempDatePicker.close();
        this.$refs.theFuelDatePicker.close();
      }
    },
    nextCurrentDate(type) {
      this[type] = dayjs(this[type]).add(1, "day").toDate();
    },
    prevCurrentDate(type) {
      this[type] = dayjs(this[type]).subtract(1, "day").toDate();
    },
    seeVehicleDetail(row) {
      this.$router.push({ path: `dashboard/detail/${row.unit_id}` });
    },
    fetchVehicleList(params) {
      getVehicleListExpress(params.data).then(
        ({ data: { code, result, message } }) => {
          if (code == 200) {
            this.device =
              result["device"] && result["device"].length > 0
                ? result["device"][0]
                : { offline: "0", idle: "0", online: "0" };
            this.vehicles = result["vehicle"]
              ? result["vehicle"].map((item) => {
                  item.vehicle_name = item.vehicle_name || item.unit_id;
                  item.longitude && item.latitude
                    ? (item.latlong = item.longitude + ", " + item.latitude)
                    : (item.latlong = " - ");
                  return item;
                })
              : [];
            this.lastUpdate = result["last_update"]
              ? result["last_update"]
              : [];
            result["vehicle"]
              ? params.success(result["vehicle"])
              : params.success([]);
          }
        },
      );
    },

    fetchVehicleDetail(id) {
      getVehicleDetail({
        unit_id: id,
        trip: dayjs().format("YYYY-MM-DD"),
      }).then(({ data: { code, result, message } }) => {
        this.vehicle = result;
      });
    },
    fetchVehicleFuel() {
      getOperatingHour({
        user_id: $cookies.get("user_id"),
        type: this.fuelType,
        date: dayjs(this.currentDateFuel).format("YYYY-MM-DD"),
        category: "operate_time",
        // date: '2020-10-20',
      }).then(({ data: { code, result, message } }) => {
        result.map((item) =>
          item.label == "00"
            ? (item.label = 0)
            : item.label.length == 2
              ? (item.label = Number(item.label))
              : nothing,
        );
        this.fuels = [];
        this.fuels = result;
      });
    },
    fetchVehicleTheFuel() {
      getFuels({
        user_id: $cookies.get("user_id"),
        type: this.theFuelType,
        date: dayjs(this.currentDateTheFuel).format("YYYY-MM-DD"),
        category: "fuel",
        // date: '2020-10-20',
      }).then(({ data: { code, result, message } }) => {
        result.map((item) =>
          item.label == "00"
            ? (item.label = 0)
            : item.label.length == 2
              ? (item.label = Number(item.label))
              : nothing,
        );
        this.theFuels = [];
        this.theFuels = result;
      });
    },
    fetchVehicleMile() {
      getOperatingMile({
        user_id: $cookies.get("user_id"),
        type: this.mileType,
        date: dayjs(this.currentDateMile).format("YYYY-MM-DD"),
        category: "operate_mileage",
        // date: '2020-10-20',
      }).then(({ data: { code, result, message } }) => {
        console.log("sebelum", result);
        result.map((item) =>
          item.label == "00"
            ? (item.label = 0)
            : item.label.length == 2
              ? (item.label = Number(item.label))
              : nothing,
        );
        console.log("sesudah", result);

        this.miles = [];
        this.miles = result;
      });
    },
    fetchVehicleIdle() {
      getIdleTime({
        user_id: $cookies.get("user_id"),
        type: this.idleType,
        date: dayjs(this.currentDateIdle).format("YYYY-MM-DD"),
        category: "idle_time",
        // date: '2020-10-20',
      }).then(({ data: { code, result, message } }) => {
        if (code == 200) {
          result.map((item) =>
            item.label == "00"
              ? (item.label = 0)
              : item.label.length == 2
                ? (item.label = Number(item.label))
                : nothing,
          );
          this.idles = [];
          this.idles = result;
        }
      });
    },
    fetchVehicleTemp() {
      getTemperature({
        user_id: $cookies.get("user_id"),
        type: this.tempType,
        date: dayjs(this.currentDateTemp).format("YYYY-MM-DD"),
        category: "temperature",
        // date: '2020-10-20',
      }).then(({ data: { code, result, message } }) => {
        if (code == 200) {
          result.map((item) =>
            item.label == "00"
              ? (item.label = 0)
              : item.label.length == 2
                ? (item.label = Number(item.label))
                : nothing,
          );
          this.temps = [];
          this.temps = result;
        }
      });
    },
    fetchVehicleSpeed() {
      getVehicleSpeed({
        type: this.speedType,
        date: dayjs(this.currentDateSpeed).format("YYYY-MM-DD"),
        // date: '2020-10-20',
      }).then(({ data: { code, result, message } }) => {
        result.map((item) =>
          item.label == "00"
            ? (item.label = 0)
            : item.label.length == 2
              ? (item.label = Number(item.label))
              : nothing,
        );
        this.speeds = [];
        this.speeds = result;
      });
    },
    initMap() {
      this.loading = true;
      var initLatLng = new window.google.maps.LatLng(
        LATITUDE_ASEAN,
        LONGITUDE_ASEAN,
      );

      var mapOptions = {
        zoom: ZOOM_ASEAN,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        center: initLatLng,
        mapTypeControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
      };
      var map = new window.google.maps.Map(
        document.getElementById("map"),
        mapOptions,
      );
      this.map = map;

      var map2 = new window.google.maps.Map(
        document.getElementById("map2"),
        mapOptions,
      );
      this.map2 = map2;

      this.infowindow = new window.google.maps.InfoWindow({
        pixelOffset: new google.maps.Size(-15, -1),
      });
      this.loading = false;
    },
    initMarker2(locations) {
      console.log(locations + " ini su");
      if (this.markers2) {
        for (var i = 0; i < this.markers2.length; i++) {
          this.markers2[i].setMap(null);
        }
      }

      if (!locations || locations.length === 0) {
        for (var i = 0; i < this.markers2.length; i++) {
          this.markers2[i].setMap(null);
        }
        this.markers2.length = 0;
        return;
      }
      var map = this.map2; //here
      var newMarker = [];
      var i;
      var bounds = new window.google.maps.LatLngBounds();

      const image = {
        off: {
          url: require("../assets/img/truck-off.png"),
          size: new window.google.maps.Size(30, 30),
        },
        idle: {
          url: require("../assets/img/truck-idle.png"),
          size: new window.google.maps.Size(30, 30),
        },
        on: {
          url: require("../assets/img/truck-on.png"),
          size: new window.google.maps.Size(30, 30),
        },
      };
      for (let i = 0; i < this.markers2.length; i++) {
        const marker = this.markers2[i];
        const location = locations.find(
          (loc) => loc.unit_id === marker.metadata.id,
        );

        if (location) {
          // Jika status berubah, update ikon marker
          if (location.status !== marker.metadata.data.status) {
            marker.setIcon(image[location.status]);
            marker.metadata.data.status = location.status; // Update status data di metadata
          }
        }
      }

      for (i = 0; i < locations.length; i++) {
        const marker2 = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            locations[i].latitude,
            locations[i].longitude,
          ),
          map: map,
          icon: image[locations[i].status],
        });
        //here
        marker2.metadata = {
          type: "point",
          id: locations[i].unit_id,
          data: locations[i],
        };

        bounds.extend(marker2.getPosition());
        var self = this;
        window.google.maps.event.addListener(
          marker2,
          "click",
          function (event) {
            self.fetchVehicleDetail(this.metadata.id);
            self.showModal = true;
          },
        );
        window.google.maps.event.addListener(
          marker2,
          "mouseover",
          function (event) {
            self.infowindow.setContent(
              self.getHoverContent(this.metadata.data),
            );
            self.infowindow.setPosition(
              new window.google.maps.LatLng(
                this.metadata.data.latitude,
                this.metadata.data.longitude,
              ),
            );
            self.infowindow.open(map, marker2);
          },
        );

        window.google.maps.event.addListener(
          marker2,
          "mouseout",
          function (event) {
            self.infowindow.close(); // Close previously opened infowindow
          },
        );

        newMarker.push(marker2);
      }
      map.fitBounds(bounds); //here
      this.markers2 = newMarker;
    },

    initMarkerWatch(locations) {
      if (this.markers) {
        for (var i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null);
        }
      }

      if (!locations || locations.length === 0) {
        for (var i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null);
        }
        this.markers.length = 0;
        return;
      }
      var map = this.map; //here

      var newMarker = [];
      var i;

      const image = {
        off: {
          url: require("../assets/img/truck-off.png"),
          size: new window.google.maps.Size(30, 30),
        },
        idle: {
          url: require("../assets/img/truck-idle.png"),
          size: new window.google.maps.Size(30, 30),
        },
        on: {
          url: require("../assets/img/truck-on.png"),
          size: new window.google.maps.Size(30, 30),
        },
      };
      for (i = 0; i < locations.length; i++) {
        const marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            locations[i].latitude,
            locations[i].longitude,
          ),
          map: map,
          icon: image[locations[i].status],
        });
        //here
        marker.metadata = {
          type: "point",
          id: locations[i].unit_id,
          data: locations[i],
        };

        var self = this;
        window.google.maps.event.addListener(marker, "click", function (event) {
          self.fetchVehicleDetail(this.metadata.id);
          self.showModal = true;
        });
        window.google.maps.event.addListener(
          marker,
          "mouseover",
          function (event) {
            self.infowindow.setContent(
              self.getHoverContent(this.metadata.data),
            );
            self.infowindow.setPosition(
              new window.google.maps.LatLng(
                this.metadata.data.latitude,
                this.metadata.data.longitude,
              ),
            );
            self.infowindow.open(map, marker);
          },
        );

        window.google.maps.event.addListener(
          marker,
          "mouseout",
          function (event) {
            self.infowindow.close(); // Close previously opened infowindow
          },
        );

        newMarker.push(marker);
      }
      this.markers = newMarker;
    },
    initMarker(locations, mapInstance) {
      if (!locations || locations.length === 0) {
        this.clearMarkers(mapInstance);
        return;
      }

      const map = mapInstance === "map2" ? this.map2 : this.map;
      const markers = mapInstance === "map2" ? this.markers2 : this.markers;
      const bounds = new window.google.maps.LatLngBounds();

      const image = {
        off: {
          url: require("../assets/img/truck-off.png"),
          size: new window.google.maps.Size(30, 30),
        },
        idle: {
          url: require("../assets/img/truck-idle.png"),
          size: new window.google.maps.Size(30, 30),
        },
        on: {
          url: require("../assets/img/truck-on.png"),
          size: new window.google.maps.Size(30, 30),
        },
      };

      // Update existing markers
      markers.forEach((marker) => {
        const location = locations.find(
          (loc) => loc.unit_id === marker.metadata.id,
        );
        if (location) {
          marker.setPosition(
            new window.google.maps.LatLng(
              location.latitude,
              location.longitude,
            ),
          );
          marker.setIcon(image[location.status]);
          marker.metadata.data = location;
          bounds.extend(marker.getPosition());
        } else {
          marker.setMap(null);
        }
      });

      // Add new markers
      locations.forEach((location) => {
        if (
          !markers.some((marker) => marker.metadata.id === location.unit_id)
        ) {
          const marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              location.latitude,
              location.longitude,
            ),
            map: map,
            icon: image[location.status],
          });
          marker.metadata = {
            type: "point",
            id: location.unit_id,
            data: location,
          };
          this.addMarkerListeners(marker, map);
          markers.push(marker);
          bounds.extend(marker.getPosition());
        }
      });

      // Remove deleted markers
      const updatedMarkers = markers.filter((marker) => {
        if (!locations.some((loc) => loc.unit_id === marker.metadata.id)) {
          marker.setMap(null);
          return false;
        }
        return true;
      });

      if (mapInstance === "map2") {
        this.markers2 = updatedMarkers;
      } else {
        this.markers = updatedMarkers;
      }

      map.fitBounds(bounds);
    },
    clearMarkers(mapInstance) {
      const markers = mapInstance === "map2" ? this.markers2 : this.markers;
      markers.forEach((marker) => marker.setMap(null));
      if (mapInstance === "map2") {
        this.markers2 = [];
      } else {
        this.markers = [];
      }
    },
    addMarkerListeners(marker, map) {
      const self = this;
      window.google.maps.event.addListener(marker, "click", function () {
        self.fetchVehicleDetail(this.metadata.id);
        self.showModal = true;
      });

      window.google.maps.event.addListener(marker, "mouseover", function () {
        self.infowindow.setContent(self.getHoverContent(this.metadata.data));
        self.infowindow.setPosition(this.getPosition());
        self.infowindow.open(map, marker);
      });

      window.google.maps.event.addListener(marker, "mouseout", function () {
        self.infowindow.close();
      });
    },
  },
  mounted() {
    this.fetchVehicleFuel();
    this.fetchVehicleSpeed();
    this.fetchVehicleIdle();
    this.fetchVehicleMile();
    this.fetchVehicleTemp();
    this.fetchVehicleTheFuel();
    this.initMap();
    this.fetchVehicleInterval();

    const that = this;
    window.$(".search input").on("search", function () {
      if (!window.$(this).val()) {
        that.$refs.table.resetSearch();
      }
    });

    $(document).ready(function () {
      $("body").animate({ scrollTop: $("#cards").offset().top }, 2800);
    });
  },
};
</script>
<style lang="scss">
@import "../assets/sass/paper/_variables.scss";

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.top-speed-legend {
  color: #fdca40 !important;
}
.avg-speed-legend {
  color: #7998b9 !important;
}
.vdp-datepicker::after {
  visibility: hidden;
}
.vdp-datepicker__calendar {
  z-index: 9999 !important;
}
.status-frame {
  width: 116px;
  height: 56px;
  box-shadow:
    0px 3px 6px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 1px 12px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  &.off {
    background-color: $matador-red-25;
  }
  &.sleep {
    background-color: $matador-bg-03;
  }
  &.idle {
    background-color: $matador-yellow-25;
  }
  &.on {
    background-color: $matador-green-25;
  }
}
.status-frame.inactive {
  background-color: $matador-bg-02;
  span {
    color: $matador-bg-04;
  }
}
.status-icon {
  width: 30px;
  height: 30px;
  margin: 5px;
}
.status-label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
#map {
  border-radius: 15px;
  min-height: 400px;
}
#map2 {
  border-radius: 15px;
  min-height: 400px;
}
.text-unit {
  font-size: 16px;
}
.map-info-title {
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: "#505565";
}
.dashboard-table-button {
  background-color: transparent;
  border: 0px solid white;
  height: 5vh;
  width: 100%;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}
.fixed-table-body {
  padding-right: 2px;
}
// .scroller {
//   height: 100vh;
//   // overflow : auto;
// 	overflow-y: visible;
//   overflow-x: hidden;
//   scroll-snap-type: y mandatory;
// }
// .sliding1 {
//   height: auto;
//   scroll-snap-align: center;
// }
// .sliding {
//   height: auto ;
//   scroll-snap-align: center;
// }
</style>
